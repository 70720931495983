import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RJSFSchema } from "@rjsf/utils";
import { IChangeEvent } from "@rjsf/core";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { ProfileEntityData } from "../../repository/models";
import { RootState } from "../../store/store";
import { selectOpenedForm, toggleForm } from "../../store/formSlice";
import { getFieldsWithoutHidden, hideDateFields, updateFiledsWithIntegerType } from "./helpers";
import { SearchJsonForm } from "../../ui/form/SearchJsonForm";


interface SearchFormProps {
    formData?: ProfileEntityData,
}

export const SearchForm = ({ formData }: SearchFormProps) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
    const opened = useTypedSelector(selectOpenedForm)
    const [values, setValues] = useState<IChangeEvent>()
    const location = useLocation()
    
    // FIX ME - temporary update of data until we have "date" format of data from backend
    const withoutIntegerForm = updateFiledsWithIntegerType(formData)
    const updatedForm = hideDateFields(withoutIntegerForm)

    const fieldsWithoutHidden = getFieldsWithoutHidden(updatedForm) as RJSFSchema | null

    const handleChange = (data: IChangeEvent) => {
        setValues(data)
    }

    const onSubmit= () => {
        if(!!values?.formData){
            const isValues = !!Object.values(values?.formData).filter(item => !!item).length

            if(isValues){

                const obj = Object.fromEntries(Object.entries(values.formData)
                    .filter(([_k, v]) => !!v)
                )

                const params = new URLSearchParams(obj)
                const searchParams = params.toString()

                navigate(`search?${searchParams}`)
                return
            }
        }
        navigate('search')
    }

    useEffect(() => {
        if(!!location.search){
            const params = new URLSearchParams(location.search)
            const entries = [...params.entries()]
            const obj = entries.reduce((o, [key, value]) => ({ ...o, [key]: value}), {})
            const updatedObj: {[k: string]: string} = { ...obj }

            setValues({ formData: updatedObj } as IChangeEvent)
            return
        }
        setValues({} as IChangeEvent)
    },[location, setValues])

    return (
        <SearchJsonForm
            opened={opened}
            onCollapseButtonClick={() => dispatch(toggleForm(!opened))}
            schema={fieldsWithoutHidden}
            formData={values?.formData}
            onSubmit={onSubmit}
            onChange={handleChange}
        />
    )
}
