import { useEffect, useState } from "react"

import { HttpResponseError } from "../repository/HttpError"
import fetcher from '../repository';

export type ContentType = {
    'content-length': string | null;
    'content-type': string | null;
    'content-name': string | null;
}

export const useGetEntityInstanceContent = (url: string) => {
  const [data, setData] = useState<ContentType | null>(null)
  const [error, setError] = useState<HttpResponseError | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setData(null)
    setIsLoading(true)
    fetcher.getEntityInstanceContent(url).then(result => {
        setData(result)
        setError(null)
        setIsLoading(false)
    }).catch(error => {
        setIsLoading(false)
        setError(error)
    })
  }, [url, setData])

  return { data, error, isLoading }
}


