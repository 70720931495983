import { parse } from 'content-disposition-attachment';

import { ProfileQueryEmbeddedItemData } from "./models"

export const updateRelatedData = (list: ProfileQueryEmbeddedItemData[], label: string) => {
  const newExistList = list.map(item => item[label]).filter(item => !!item)

  return [...new Set(newExistList)].join(' ● ')
}

export function getFilenameFromContentDisposition(value: string) {
  const disposition = parse(value)

  if('filename' in disposition){
    return disposition.filename ? disposition.filename : null
  }

  return null;
}
