import { User as OidcUser } from "oidc-client-ts"

import IDataFetcher from "./IDataFetcher";
import { HttpJsonError } from "./HttpError";
import { ProfileEntityData, ProfileList, ProfileQueryData, ProfileQueryEmbeddedItemData } from "./models";
import { getFilenameFromContentDisposition, updateRelatedData } from "./helpers";
import * as jaaropgaveData from '../json-schema/jaaropgave-documents.json';
import * as loonstrookData from '../json-schema/loonstrook-documents.json';
import * as factuurData from '../json-schema/factuur-documents.json';
import * as lijstwerkData from '../json-schema/lijstwerk-documents.json';
import { getConfig } from "../helpers";

const exceptionJaaropgaveSlug = 'jaaropgave-documents'
const exceptionLoonstrookSlug = 'loonstrook-documents'
const exceptionFactuurSlug = 'factuur-documents'
const exceptionLijstwerkSlug = 'lijstwerk-documents'

export default class DataFetcher implements IDataFetcher {
    private config = getConfig()
    private baseUrl = this.config?.baseUrl
    private authority = this.config?.oidc?.authority
    private clientId = this.config?.oidc?.client_id

    public async getProfileData(): Promise<ProfileList> {
        const jsonData = await this.fetch(this.baseUrl+'profile');

        return jsonData.json();
    }

    public async getEntityProfileData(slug: string): Promise<ProfileEntityData> {
        // FIXME. temporary solution
        switch (slug) {
            case exceptionJaaropgaveSlug: return jaaropgaveData;
            case exceptionLoonstrookSlug: return loonstrookData;
            case exceptionFactuurSlug: return factuurData;
            case exceptionLijstwerkSlug: return lijstwerkData;
            default:
                const jsonData = await this.fetch(this.baseUrl+'profile/'+slug, {
                    headers: { Accept: 'application/schema+json' }
                });

                return jsonData.json();
        }
    }

    public async getQueryData(slug: string, query: string): Promise<ProfileQueryData> {
        const response = await this.fetch(this.baseUrl+slug+query);
        const jsonData = await response.json()
        return jsonData;
    }

    public async getQueryDataByUrl(url: string): Promise<ProfileQueryData> {
        const response = await this.fetch(url);
        const jsonData = await response.json()
        return jsonData;
    }

    public async getDebiteurQueryDataByUrl(url: string): Promise<ProfileQueryEmbeddedItemData[]> {
        const response = await this.fetch(url);
        const jsonData = await response.json()
        return jsonData._embedded.debiteurs;
    }

    public getAccessToken(): string {
        return this.getToken() as string
    }

    public async getQueryFactuurData(slug: string, query: string): Promise<ProfileQueryData> {
        const jsonData = await this.getQueryData(slug, query)
       
        const debiteurDataList = [] as ProfileQueryEmbeddedItemData[]

        const list = jsonData._embedded[exceptionFactuurSlug]

        for(let i = 0; i < list.length; i++){
            const jsonDebiteurData = await this.getDebiteurQueryDataByUrl(list[i]._links.debiteur.href)
            const data = {
                'debiteur_nr': updateRelatedData(jsonDebiteurData, 'debiteur_nr'),
                'debiteur_naam': updateRelatedData(jsonDebiteurData, 'debiteur_naam'),
            } as {[k: string]: string | string[]}

            const result = { ...list[i], ...data }

            debiteurDataList.push(result)
        }

        const newList = { ...jsonData, _embedded: { [exceptionFactuurSlug]: debiteurDataList }}
   
        return newList;
    }

    public async getQueryJaaropgaveData(slug: string, query: string): Promise<ProfileQueryData> {
        const jsonData = await this.getQueryData(slug, query)
       
        const werknemerDataList = [] as ProfileQueryEmbeddedItemData[]

        const list = jsonData._embedded[exceptionJaaropgaveSlug]

        for(let i = 0; i < list.length; i++){
            const jsonWerknemerData = await this.getQueryDataByUrl(list[i]._links.werknemer.href)

            const werknemerList = jsonWerknemerData._embedded['werknemers']

            const data = {
                bsn: updateRelatedData(werknemerList, 'bsn'),
                'werknemer_fms_code': updateRelatedData(werknemerList, 'werknemer_fms_code'),
                'werknemer_naam': updateRelatedData(werknemerList, 'werknemer_naam'),
            } as {[k: string]: string | string[]}

            const result = { ...list[i], ...data }

            werknemerDataList.push(result)
        }

        const newList = { ...jsonData, _embedded: { [exceptionJaaropgaveSlug]: werknemerDataList }}
   
        return newList;
    }

    public async getQueryLijstwerkData(slug: string, query: string): Promise<ProfileQueryData> {
        const jsonData = await this.getQueryData(slug, query)
       
        const werkmaatschappijenDataList = [] as ProfileQueryEmbeddedItemData[]

        const list = jsonData._embedded[exceptionLijstwerkSlug]

        for(let i = 0; i < list.length; i++){
            const jsonWerkmaatschappijData = await this.getQueryDataByUrl(list[i]._links.werkmaatschappij.href)

            const data = {
                'werkmaatschappij_id': 'werkmaatschappij_id' in jsonWerkmaatschappijData ? jsonWerkmaatschappijData['werkmaatschappij_id'] : '',
                'werkmaatschappij_titel': 'werkmaatschappij_titel' in jsonWerkmaatschappijData ? jsonWerkmaatschappijData['werkmaatschappij_titel'] : '',
                'werkmaatschappij_naam': 'werkmaatschappij_naam' in jsonWerkmaatschappijData ? jsonWerkmaatschappijData['werkmaatschappij_naam'] : '',
            } as {[k: string]: string | string[]}

            const result = { ...list[i], ...data }

            werkmaatschappijenDataList.push(result)
        }

        const newList = { ...jsonData, _embedded: { [exceptionLijstwerkSlug]: werkmaatschappijenDataList }}
   
        return newList;
    }

    public async getContent(url: string): Promise<Blob> {
        const response = await this.fetch(url);
        const data = await response.blob()
        return data;
    }

    public async getEntityInstanceContent(url: string): Promise<any> {
        const response = await this.fetch(url, {
            headers: {'Accept': "*/*"},
            method: 'HEAD'
        })

        const length = response?.headers.get('content-length')
        const type = response?.headers.get('content-type')
        const disposition = response?.headers.get('content-disposition')
        const name = disposition ? getFilenameFromContentDisposition(disposition) : null

     
        return {
            'content-length': length,
            'content-type': type,
            'content-name': name
        }
    }

    private getToken() : String | null {
        var oidcKey = `oidc.user:${this.authority}:${this.clientId}`;
        const oidcStorage = sessionStorage.getItem(oidcKey);
        if (!oidcStorage) {
            return null;
        }

        var oidcUser =  OidcUser.fromStorageString(oidcStorage);
        return oidcUser?.access_token;
    }

    private async fetch(url: string, init?: (RequestInit | undefined)): Promise<Response> {
        const token = this.getToken();
        let method = init?.method ?? "GET";
        if (!token) {
            throw console.log('Error')
        }

        init = {
            ...init,
            headers: {
                ...init?.headers,
                Authorization: `Bearer ${token}`
            }
        }

        const response = await fetch(url, init);
        if(!response.ok) {
            throw await HttpJsonError.tryFromResponse(method, url, response);
        }

        return response;
    }
}
