import { ForwardedRef, ReactNode, forwardRef, useEffect, useRef, useState } from 'react';
import Draggable, { DraggableData } from 'react-draggable';
import { Alert, Box, Button, CircularProgress, IconButton, Typography, styled } from '@mui/material';
import cx from 'classnames'
import { useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

import { PdfViewer } from '../../PdfViewer/PdfViewer';
import { calculateDraggablePane, getTableColumnSizes } from './helpers';
import { TableBody } from './components/TableBody';
import { theme } from '../../../theme';
import { ProfileQueryEmbeddedItemData } from '../../../repository/models'
import fetcher from '../../../repository/index'
import { ContentType, useGetEntityInstanceContent } from '../../../hooks/useGetEntityInstanceContent';
import { makeStyles } from '@mui/styles';

interface TableProps {
    rows: ProfileQueryEmbeddedItemData[] | null,
    title: string,
}

export const Table = ({ rows, title }: TableProps) => {
    const [ contentUrl, setContentUrl ] = useState('')
    const [ pdfLoaded, setPdfLoaded ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ dividerLocation, setDividerLocation ] = useState(0)
    const [ opened, setOpened ] = useState(false)
    const location = useLocation()
    const [index, setIndex] = useState<number | null>(null)

    const handleDrag = (data: DraggableData) => {
        setDividerLocation((window.outerWidth/2) + data.x)
    }

    useEffect(() => {
        // Should be updated later, size on block when spinner is running
        if(loading){
            setDividerLocation(window.outerWidth/2)
        }
        if(pdfLoaded){
            setDividerLocation(window.outerWidth/2)
        }
        if(opened){
            setDividerLocation(window.outerWidth/2)
        }
    }, [pdfLoaded, loading, opened, setDividerLocation])

    const draggableData = calculateDraggablePane()

    const handleClose = () => {
        setContentUrl('')
        setPdfLoaded(false)
        setDividerLocation(0)
        setOpened(false)
        setLoading(false)
        setIndex(null)
    }

    useEffect(() => () => {
        handleClose()
    }, [])

    useEffect(() => {
        handleClose()
    },[location])

    useEffect(() => {
        if(index !== null && !!rows){
            const selectedRow = rows[index]
            const rowLinks = selectedRow._links
            const contentUrl = rowLinks.content?.href

            setContentUrl(contentUrl)
            setOpened(true)
            return
        }
        handleClose()
    }, [ index, setContentUrl, setOpened, rows ])

    const classes = cx({ 'is-hidden': rows === null })

    const leftColumnRef = useRef<HTMLDivElement>(null)
    const rightColumnRef = useRef<HTMLDivElement>(null)

    getTableColumnSizes(
        rightColumnRef,
        leftColumnRef,
        opened,
        !!contentUrl,
        dividerLocation
    )

    return (
        <StyledTable className={classes}>
            <TableBody
                ref={leftColumnRef}
                rows={rows ?? []}
                title={title}
                selectedIndex={index}
                onSelectIndex={setIndex}
            />

            {!!contentUrl && <>
                <Draggable
                    axis="x"
                    bounds={draggableData}
                    onDrag={(_e, value) => handleDrag(value)}
                >
                    <StyledDivider style={{ left: dividerLocation }} />
                </Draggable>

                <ViewerWrapper
                    ref={rightColumnRef}
                    url={contentUrl}
                    pdfLoaded={pdfLoaded}
                    onLoad={() => setPdfLoaded(true)}
                    onLoading={setLoading}
                    onClose={handleClose}
                />
            </>}
        </StyledTable>
    )
}

const StyledTable = styled('div')({
    display: 'flex',
    width: '100%',
    '&.is-hidden': {
        opacity: 0
    }
})

const StyledDivider = styled('span')({
    height: '100%',
    minWidth: '3rem',
    transform: 'none !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'col-resize',
    marginTop: '1.5rem',
    '&::before': {
        content: '""',
        display: 'block',
        width: '12px',
        height: '20px',
        borderLeft: '3px solid '+theme.palette.primary.main,
        borderRight: '3px solid '+theme.palette.primary.main,
        transition: 'width 150ms ease,height 150ms ease,transform 150ms ease,background-color 150ms ease,-webkit-transform 150ms ease'
    },
    '&:hover': {
        '&::before': {
            borderColor: theme.palette.primary.dark,
            transition: 'transform 200ms eae-out',
            transform: 'scale(1.2)',
        }
    },
})

interface ViewerWrapperProps {
    url: string,
    pdfLoaded: boolean,
    onLoad: () => void,
    onLoading: (value: boolean) => void,
    onClose: () => void,
}

export const ViewerWrapper = forwardRef((props: ViewerWrapperProps, ref: ForwardedRef<any>) => {
    const {data, error, isLoading} = useGetEntityInstanceContent(props.url)

    return (
        <StyledRightColumn ref={ref}>
            {isLoading ? (
                <ContentWrapper onClose={props.onClose}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }} marginBottom={2}>
                        <CircularProgress />
                    </Box>
                </ContentWrapper>
            ) : error ? (
                <ContentWrapper onClose={props.onClose}>
                    <Alert severity='error'>
                        <Box>{error?.message}</Box>
                    </Alert>
                </ContentWrapper>
            ) : data ? (
                <ViewerContent {...props} data={data} />
            ) : null}
        </StyledRightColumn>
    )
})

export const ViewerContent = (props: ViewerWrapperProps & { data: ContentType }) => {
    const { data, url, pdfLoaded, onLoad, onLoading, onClose } = props;

    if(data["content-type"] === 'application/pdf'){
        return  <PdfViewer pdfLoaded={pdfLoaded} url={url} onLoad={onLoad} onLoading={onLoading} onClose={onClose} />
    }

    if(data["content-type"]?.startsWith('image/')){
        return (
            <ImagePreview {...props} />
        )
    }

    return (
        <ContentWrapper onClose={onClose}>
            <Box marginTop={2} display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                <Typography gutterBottom>Preview is not available.</Typography>
                <Button href={props.url} variant='outlined'>Download</Button>
            </Box>
        </ContentWrapper>
    )
}

const StyledRightColumn = styled('div')({
    height: 'calc(100vh - 372px)',
    '& ::-webkit-scrollbar': {
        display: 'none'
    },
})

interface ImagePreviewProps {
    url: string, data: ContentType, onClose: () => void
}

const ImagePreview = (props: ImagePreviewProps) => {
    const [src, setSrc] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const classes = useStyles()

    useEffect(() => {
        setLoading(true)
        fetch(props.url, {
            headers: {
                "Authorization": "Bearer "+fetcher.getAccessToken(),
                'Accept': "image/*"
            }
        })
        .then(response => response.blob())
        .then(blob => {
            const url = URL.createObjectURL(blob);
            setSrc(url)
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        });
    },[props.url])

    return (
        <ContentWrapper onClose={props.onClose}>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }} marginBottom={2}>
                    <CircularProgress />
                </Box>
            ) : src ? (
                <div className={classes.imageWrapper}><img className={classes.image} src={src} alt={props.data['content-name'] || 'content image'} /></div>
            ) : <Button href={props.url} variant='outlined'>Download</Button> }
        </ContentWrapper>
    )
}

const ContentWrapper = ({children, onClose} : {children: ReactNode, onClose: () => void}) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <div className={classes.close}>
                <IconButton style={{cursor: "pointer"}} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            </div>

            {children}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    imageWrapper:{
        overflow: 'hidden'
    },
    image:{
        height: 'auto',
        width: 'auto',
        maxHeight: '100%',
        maxWidth: '100%',
        display: 'inline-block'
    },
    close: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1)
    },
    wrapper: {
        background: theme.palette.common.white,
        padding: theme.spacing(1),
        paddingTop: theme.spacing(6),
        position: 'relative'
    }
}));
