import { Alert, Box, CircularProgress, IconButton, alpha, styled } from '@mui/material';
import { LoadError, Viewer } from '@react-pdf-viewer/core';
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import '@react-pdf-viewer/core/lib/styles/index.css';
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import {RenderCurrentScaleProps, RenderZoomInProps, RenderZoomOutProps} from '@react-pdf-viewer/zoom';
import {RenderDownloadProps} from "@react-pdf-viewer/get-file";
import {RenderPrintProps} from "@react-pdf-viewer/print";
import {RenderEnterFullScreenProps} from '@react-pdf-viewer/full-screen';
import { useEffect } from 'react';

import fetcher from '../../repository';

interface PdfViewerProps {
    url: string,
    pdfLoaded: boolean,
    onLoad: () => void,
    onLoading: (value: boolean) => void,
    onClose: () => void,
}

export const PdfViewer = ({ url, onLoad, pdfLoaded, onLoading, onClose }: PdfViewerProps) => {
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;

    const token = fetcher.getAccessToken()
    
    const headers = {"Authorization": `Bearer ${token}`}

    return (
        <StyledWrapper>
            {!!url && !!token && (
                <>
                    {pdfLoaded ? (
                        <StyledToolbarWrapper>
                            <Toolbar>
                                {(props: ToolbarSlot) => {
                                    const {
                                        EnterFullScreen,
                                        CurrentScale,
                                        ZoomIn,
                                        ZoomOut,
                                        Download,
                                        Print
                                    } = props;
                                    return (
                                        <>
                                            <Box>
                                                <ZoomOut>
                                                    {(props: RenderZoomOutProps) => (
                                                        <IconButton style={{cursor: "pointer"}} onClick={props.onClick}>
                                                            <ZoomOutIcon/>
                                                        </IconButton>
                                                    )}
                                                </ZoomOut>
                                            </Box>
                                            <Box>
                                                <CurrentScale>
                                                    {(props: RenderCurrentScaleProps) => (
                                                        <span>{`${Math.round(props.scale * 100)}%`}</span>
                                                    )}
                                                </CurrentScale>
                                            </Box>
                                            <Box>
                                                <ZoomIn>
                                                    {(props: RenderZoomInProps) => (
                                                        <IconButton style={{cursor: "pointer"}} onClick={props.onClick}>
                                                            <ZoomInIcon/>
                                                        </IconButton>
                                                    )}
                                                </ZoomIn>
                                            </Box>
                                            <Box sx={{marginLeft: "auto"}}>
                                                <Download>
                                                    {(props: RenderDownloadProps ) => (
                                                        <IconButton style={{cursor: "pointer"}} onClick={props.onClick}>
                                                            <DownloadIcon/>
                                                        </IconButton>
                                                    )}
                                                </Download>
                                                <Print>
                                                    {(props: RenderPrintProps) => (
                                                        <IconButton style={{cursor: "pointer"}} onClick={props.onClick}>
                                                            <PrintIcon/>
                                                        </IconButton>
                                                    )}
                                                </Print>
                                                <EnterFullScreen>
                                                    {(props: RenderEnterFullScreenProps) => (
                                                        <IconButton style={{cursor: "pointer"}} onClick={props.onClick}>
                                                            <FullscreenIcon/>
                                                        </IconButton>
                                                    )}
                                                </EnterFullScreen>
                                                <IconButton style={{cursor: "pointer"}} onClick={onClose}>
                                                    <CloseIcon/>
                                                </IconButton>
                                            </Box>
                                        </>
                                    );
                                }}
                            </Toolbar>
                        </StyledToolbarWrapper>
                    ) : (
                        <StyledCloseWrapper>
                            <IconButton style={{cursor: "pointer"}} onClick={onClose}>
                                <CloseIcon/>
                            </IconButton>
                        </StyledCloseWrapper>
                    )}

                

                    <Viewer
                        fileUrl={url}
                        httpHeaders={headers}
                        plugins={[toolbarPluginInstance]}
                        renderError={renderError}
                        renderLoader={() => <CustomLoader onLoad={() => onLoading(true)} />}
                        onDocumentLoad={onLoad}
                    />
                </>
            )}
        </StyledWrapper>
    );
}

const renderError = (error: LoadError) => {
    let message = '';
    switch (error.name) {
        case 'InvalidPDFException':
            message = 'The document is invalid or corrupted';
            break;
        case 'MissingPDFException':
            message = 'The document is missing';
            break;
        case 'UnexpectedResponseException':
            message = 'Unexpected server response';
            break;
        default:
            message = 'Cannot load the document';
            break;
    }

    return (
        <StyledErrorWrapper>
            <Alert severity='error' variant='filled' icon={false}>
                {message}
            </Alert>
        </StyledErrorWrapper>
    );
};

const StyledErrorWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    height: 'calc(100vh - 372px)',
    justifyContent: 'center',
    backgroundColor: alpha(theme.palette.error.light, 0.2),
    borderRadius: '0.25rem',
    width: '100%',
    alignItems: 'center'
}))

const CustomLoader = ({ onLoad }: { onLoad: () => void})  => {
    useEffect(() => {
        onLoad()
    }, [onLoad])

    return (
        <StyledLoaderWrapper>
            <CircularProgress />
        </StyledLoaderWrapper>
    )
}

const StyledLoaderWrapper = styled('div')(() => ({
    display: 'flex',
    height: 'calc(100vh - 372px)',
    justifyContent: 'center',
    borderRadius: '0.25rem',
    alignItems: 'center'
}))

const StyledWrapper = styled('div')({
    backgroundColor: '#fff',
    borderRadius: '0.25rem',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',

})

const StyledCloseWrapper = styled('div')({
    position: 'absolute',
    right: '1rem',
    top: '1rem',
})

const StyledToolbarWrapper = styled('div')({
    alignItems: 'center',
    display: 'flex',
    minHeight: '4rem',
    boxSizing: 'border-box',
    flex: '1 0',
    padding: '0 2rem'
})
